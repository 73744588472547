/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import CheckPermissionOnClick from '../../SubscriptionManager/CheckPermissionOnClick';

import styles from '../Integrations.module.scss';

import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-add-section.svg';
import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';

export class DrawerIntegrations extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showError: false,
      errorContent: {},
      isSubmitting: false,
      config: props.integration
        ? props.integration.config
        : props.integrationSlug === 'google_recaptcha'
        ? { required_score: 0.7 }
        : {},
    };
  }

  handleChange = (event) => {
    this.setState({
      config: {
        ...this.state.config,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const newConfig = { ...this.state.config };
    const fields = this.props.authenticator.form_info.fields;
    this.setState({ isSubmitting: true });
    fields.forEach((field) => {
      if (field.type === 'password' && this.props.integration && this.props.integration.config[field.name]) {
        delete newConfig[field.name];
      }
    });
    (!this.props.integration ? this.createIntegration(newConfig) : this.updateIntegration(newConfig)).promise
      .then((response) => {
        this.props.receiveUpdate(response);
        this.setState({ isSubmitting: false, showError: false, isOpen: false });
      })
      .catch((err) => {
        console.error(err);
        if (err) {
          this.setState({ isSubmitting: false, showError: true, errorContent: err });
        } else this.setState({ isSubmitting: false, errorContent: { error: ['Something went wrong'] } });
      });
  };

  createIntegration = (config) => {
    const { siteId } = this.props;
    return this.props.create({
      integration: this.props.integrationSlug,
      siteId,
      config,
    });
  };

  updateIntegration = (config) => {
    const { id } = this.props.integration;

    return this.props.patch({ id, config });
  };

  deleteIntegration = () => {
    const { id } = this.props.integration;

    this.props
      .deleteIntegrationRequest(id)
      .promise.then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.setState({
          isOpen: false,
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.integration !== this.props.integration && this.props?.integration?.config) {
      this.setState({ config: this.props.integration.config });
    }
  }

  render() {
    const { config, errorContent } = this.state;
    const {
      integration,
      siteId,
      type,
      icon,
      name,
      integrationSlug,
      authenticator: {
        form_info: { fields },
      },
      handleClose,
    } = this.props;
    const isEmpty = !integration;

    return (
      <>
        {this.props.showTableContent && (
          <>
            <img className={styles.icon} src={icon} alt="" />
            <div className={styles.title}>{name}</div>
            <div className={styles.config}>
              {isEmpty && (
                <CheckPermissionOnClick
                  action={`SiteIntegration:create:::${integrationSlug}`}
                  onClick={() => this.setState({ isOpen: true })}
                  render={(handleClick, hasPermission) =>
                    hasPermission ? (
                      <SvgIconAdd className={styles.add} onClick={handleClick} role="button" />
                    ) : (
                      <button onClick={handleClick} className="button button-primary button-sm">
                        Upgrade
                      </button>
                    )
                  }
                />
              )}
              {!isEmpty && (
                <>
                  <label>
                    <span className={styles.statusIndicator} />
                    Connected
                  </label>{' '}
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ isOpen: true });
                    }}
                    className="button button-sm"
                  >
                    Edit
                  </a>
                </>
              )}
            </div>
          </>
        )}
        <SideDrawer
          isOpen={this.state.isOpen || this.props.isOpen}
          title={`${name} settings`}
          close={() => {
            this.setState({ isOpen: false, showError: false, errorContent: {} });
            if (handleClose) {
              handleClose();
            }
          }}
        >
          <form onSubmit={this.handleSubmit}>
            {fields.map((field) => (
              <fieldset key={field.name}>
                {field.label && <label>{field.label}</label>}
                {field.name && (
                  <FormControl
                    {...(field.type !== 'text' && field.type !== 'checkbox' && field.type !== 'password'
                      ? { tag: field.type }
                      : { type: field.type })}
                    {...(field.type === 'checkbox' && { checked: config[field.name] })}
                    name={field.name}
                    onChange={this.handleChange}
                    value={config[field.name] || ''}
                    autoComplete="off"
                    placeholder={field.placeholder}
                    origValue={integration?.config[field.name]}
                  >
                    {field.options && (
                      <>
                        <option value=""></option>
                        {field.options.map((option) => (
                          <option key={option[0]} value={option[0]}>
                            {option[1]}
                          </option>
                        ))}
                      </>
                    )}
                  </FormControl>
                )}
                <small
                  style={{
                    display:
                      this.state.showError &&
                      (!config[field.name] || errorContent?.config?.[field.name]) &&
                      field.type !== 'info'
                        ? 'block'
                        : 'none',
                    color: 'red',
                  }}
                >
                  {errorContent?.config?.[field.name] ? errorContent.config[field.name] : 'This field is required'}
                </small>
                {field.help_text && (
                  <small>
                    {field.help_text.map((line) =>
                      line.type === 'hyperlink' ? (
                        <a href={line.href} target="_blank" rel="noopener noreferrer" className={styles.actionLink}>
                          {line.text}
                        </a>
                      ) : (
                        line
                      )
                    )}
                  </small>
                )}
                {field.type === 'info' && (
                  <small>
                    {field.text.map((i) =>
                      i.type === 'hyperlink' ? (
                        <a href={i.href} target="_blank" rel="noopener noreferrer" className={styles.actionLink}>
                          {i.text}
                        </a>
                      ) : (
                        i
                      )
                    )}
                  </small>
                )}
              </fieldset>
            ))}
            {Boolean(integrationSlug === 'google_recaptcha' && config.version == 3) && (
              <fieldset>
                <label>reCAPTCHA threshold</label>
                <FormControl
                  type="number"
                  name="required_score"
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                  value={config.required_score || 0.7}
                  autoComplete="off"
                  max={1}
                  step="0.1"
                  min={0.1}
                />
              </fieldset>
            )}
            <fieldset>
              <input
                type="submit"
                value={isEmpty ? 'Connect account' : 'Update account'}
                className="button button-primary button-full"
              />
              {errorContent?.error && <small className={styles.error}>Error: {errorContent.error[0]}</small>}
              {!isEmpty && (
                <a
                  className={`button button-full ${styles.remove}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const remove = window.confirm(`Are you sure you want to disconnect from ${name}?`);
                    if (remove) {
                      this.deleteIntegration();
                    }
                  }}
                  role="button"
                >
                  Remove
                </a>
              )}
            </fieldset>
          </form>
        </SideDrawer>
      </>
    );
  }
}

export default DrawerIntegrations;
