const images = [
  'https://cdn.spark.app/media/www/image/bylt.svg',
  'https://cdn.spark.app/media/www/image/crossnet.svg',
  'https://cdn.spark.app/media/www/image/united_logo_traced_.svg',
  'https://cdn.spark.app/media/www/image/collers_co.png',
  'https://cdn.spark.app/media/www/image/perk_Bhs8HMd.svg',
  'https://cdn.spark.app/media/www/image/taco.svg',
  'https://cdn.spark.app/media/www/image/wristmafia.png',
  'https://cdn.spark.app/media/www/image/regatta_UOakgXo.png',
  'https://cdn.spark.app/media/www/image/engraven.png',
  'https://cdn.spark.app/media/www/image/airboxr_Pibcd0h.png',
  'https://cdn.spark.app/media/www/image/wilder_toys_PRv0lzi.svg',
  'https://cdn.spark.app/media/www/image/arcade1.svg',
  'https://cdn.spark.app/media/www/image/scroobious_7MXd0QK.png',
  'https://cdn.spark.app/media/www/image/compt_dWPiVEp.png',
];

import React, { useState, useEffect, useRef } from 'react';

export const LogosBanner = () => {
  const containerRef = useRef(null);
  const [imageCount, setImageCount] = useState(images.length);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let scrollInterval: any;
    const scrollSpeed = 0.5; // Adjust speed as needed
    const imageWidth = 150; // Adjust according to your image width

    const startScroll = () => {
      scrollInterval = setInterval(() => {
        container.scrollLeft += scrollSpeed;

        // Reset scroll position to start when reaching the end
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          container.scrollLeft = 0;
        }
      }, 10); // Adjust the interval time for smoother or faster scrolling
    };

    startScroll();

    // Clean up interval on component unmount
    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [imageCount]);

  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
        padding: '10px',
        position: 'relative',
        background: '#FFF',
      }}
    >
      {/* Duplicate the images to create an infinite effect */}
      {[...images, ...images].map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index}`}
          style={{ height: '42px', marginRight: '48px' }} // Adjust as needed
        />
      ))}
    </div>
  );
};

export default LogosBanner;
